/**
 * Navigation
 */

import {needJquery} from '../../../../shared/js/utils/index';

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../../shared/js/utils/scroll';

import SelectorEngine from '../../../../shared/js/dom/selector-engine';
import Manipulator    from '../../../../shared/js/dom/manipulator';
import EventHandler   from '../../../../shared/js/dom/event-handler';

import Drawer from '../../../../shared/components/drawer/drawer';

// -------
// Private
// -------

const $           = needJquery();
const pageHeader   = SelectorEngine.findOne('#page-header');
const pageMainMenu = (pageHeader) ? SelectorEngine.findOne('#main-nav') : null;

let subNav = null;
let Panel  = null;

// Klick auf einen Navigationseintrag öffnet und schließt ein Untermenü.
// Soll statt dem ´Schließen´ der Link des geklickten Navigationseintrages
// beachtet werden?
const navForceLink = false;

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const pageSubMenu = SelectorEngine.findOne('#page-submenu-drawer');

	if (pageSubMenu) {
		const collection = SelectorEngine.find('.nav-item.-has-nav', pageHeader);
		const drawer     = new Drawer(pageSubMenu, {
			useOverlay: true,
			onShow    : function (el) {
				// Hier wird eine jQuery-Instanz zurückgegeben.
				const element = el[0];

				el.target.querySelector('.drawer__body .main-nav .nav').innerHTML = subNav;

				// Scrollen des `<body/>` sperren
				// lockBodyScrolling(element);
			},
			onHide    : function (el) {
				// Hier wird eine jQuery-Instanz zurückgegeben.
				const element = el[0];

				// Scrolen des `<body/>` entsperren.
				// unlockBodyScrolling(element);
			}
		});

		for (const trigger of collection) {
			EventHandler.on(trigger, 'mouseenter.ifab.pageSubMenuDrawer', function (event) {
				// event.preventDefault();
				subNav = trigger.querySelector('.nav').innerHTML;

				const collection = SelectorEngine.find('[data-drawer].-initialized', document.body);

				for (const element of collection) {
					if (element.classList.contains('-open')) {
						if (element === pageSubMenu) {
							pageSubMenu.querySelector('.drawer__body .main-nav .nav').innerHTML = subNav;
						} else {
							Drawer(element).hide();
						}
					} else {
						drawer.show();
					}
				}
			});

			EventHandler.on(trigger, 'mouseleave.ifab.pageSubMenuDrawer', function (event) {
				checkHovering(drawer);
			});
		}

		Panel = SelectorEngine.findOne('.drawer__panel', pageSubMenu);

		EventHandler.on(Panel, 'mouseout.ifab.pageSubMenuDrawer', function (event) {
			checkHovering(drawer);
		});
	}
};

// Check if User hovers over Drawer-Submenu or Main-Menu in Page-Header
const checkHovering = (el) => {
	setTimeout(function() {
		if(!pageMainMenu.matches(':hover') && !Panel.matches(':hover')) {
			el.hide();
		}
	}, 500);
}

// -------
// Public
// -------

const init = () => {
	if (pageMainMenu) {
		setupDrawer();
	}
};

// Export
export default {
	init  : init
};
